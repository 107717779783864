/** *** UI STATE ***** */
const initialState = {
  sidebarOpen: false,
  appbarHeight: '45px',
  subSidebarOpen: false,
};

const UIReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_SIDEBAR_OPEN_STATE':
    {
      const clone = Object.assign({}, state);
      console.log("clone");
      console.log(clone);
      clone.sidebarOpen = action.sidebarOpen;
      console.log(clone);

      return clone;
    }

    case 'SET_SUB_SIDEBAR_OPEN_STATE':
    {
      const clone = Object.assign({}, state);
      console.log("clone");
      console.log(clone);
      clone.subSidebarOpen = action.subSidebarOpen;
      console.log(clone);

      return clone;
    }

    case 'SET_APP_BAR_HEIGHT':
    {
      const clone = Object.assign({}, state);
      clone.appbarHeight = action.appbarHeight;
      return clone;
    }



    

    default:
      return state;
  }
};

export default UIReducer;
