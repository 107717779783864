/** *** USER ***** */

const initialState = {
  profile: {
    uid: '',
    firstName: '',
    lastName: '',

    primaryEmail: '',
    primaryEmailVerified: '',
    primaryMobile: '',
    primaryMobileVerified: '',
    primaryMobileNumber: '',
    telephone: '',
    telephoneVerified: '',
    userAddress: {
      name,
      addressLine1: '',
      addressLine2: '',
      addressCity: '',
      addressState: '',
      addressPostcode: '',
      addressCountry: '',
      addressNote: '',
      primaryAddress: '',
    },
  },
  managedBusinesses: [],

};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_USER_DATA':
    {
      const clone = Object.assign({}, state);
      console.log('SET_USER_DATA');
      console.log(action);
      clone.profile.uid = action.data.user.uid;
      clone.profile.firstName = action.data.user.firstName;
      clone.profile.lastName = action.data.user.lastName;

      clone.profile.primaryEmail = action.data.user.primaryEmail;
      clone.profile.primaryEmailVerified = action.data.user.primaryEmailVerified;
      clone.profile.primaryMobile = action.data.user.primaryMobile;
      clone.profile.primaryMobileVerified = action.data.user.primaryMobileVerified;
      clone.profile.telephone = action.data.user.telephone;
      clone.profile.telephoneVerified = action.data.user.telephoneVerified;

      clone.profile.userAddress.name = action.data.user.userAddress.name;
      clone.profile.userAddress.addressLine1 = action.data.user.userAddress.addressLine1;
      clone.profile.userAddress.addressLine2 = action.data.user.userAddress.addressLine2;
      clone.profile.userAddress.addressCity = action.data.user.userAddress.addressCity;
      clone.profile.userAddress.addressState = action.data.user.userAddress.addressState;
      clone.profile.userAddress.addressPostcode = action.data.user.userAddress.addressPostcode;
      clone.profile.userAddress.addressCountry = action.data.user.userAddress.addressCountry;
      clone.profile.userAddress.addressNote = action.data.user.userAddress.addressNote;
      clone.profile.userAddress.primaryAddress = action.data.user.userAddress.primaryAddress;

      return clone;
    }

    case 'SET_USER_PROFILE':
    {
      const clone = Object.assign({}, state);
      // clone.profile = action.userProfile;
      console.log('SET_USER_PROFILE');
      console.log(action);

      clone.profile.firstName = action.data.userInformation.firstName;
      clone.profile.lastName = action.data.userInformation.lastName;

      clone.profile.primaryEmail = action.data.userContactInformation.primaryEmail;
      clone.profile.primaryEmailVerified = action.data.userContactInformation.primaryEmailVerified;
      clone.profile.primaryMobile = action.data.userContactInformation.primaryMobile;
      clone.profile.primaryMobileVerified = action.data.userContactInformation.primaryMobileVerified;
      clone.profile.telephone = action.data.userContactInformation.telephone;
      clone.profile.telephoneVerified = action.data.userContactInformation.telephoneVerified;

      clone.profile.userAddress.name = action.data.userAddress.name;
      clone.profile.userAddress.addressLine1 = action.data.userAddress.addressLine1;
      clone.profile.userAddress.addressLine2 = action.data.userAddress.addressLine2;
      clone.profile.userAddress.addressCity = action.data.userAddress.addressCity;
      clone.profile.userAddress.addressState = action.data.userAddress.addressState;
      clone.profile.userAddress.addressPostcode = action.data.userAddress.addressPostcode;
      clone.profile.userAddress.addressCountry = action.data.userAddress.addressCountry;
      clone.profile.userAddress.addressNote = action.data.userAddress.addressNote;
      clone.profile.userAddress.primaryAddress = action.data.userAddress.primaryAddress;

      return clone;
    }

    case 'REMOVE_USER_DATA':
    {
      console.log('removing user data');
      return initialState;
    }



    case 'SET_USER_MANAGED_BUSINESS':
    {
      const clone = Object.assign({}, state);
      // clone.profile = action.userProfile;
      console.log('SET_USER_PROFILE');
      console.log(action);

      clone.managedBusinesses = action.user.managedBusinesses;

      return clone;
    }


    default:
      return state;
  }
};

export default UserReducer;
