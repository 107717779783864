import React, { Component } from 'react';
import Nprogress from 'nprogress';
// https://www.npmjs.com/package/react-nprogress
import 'nprogress/nprogress.css';

// import ReactPlaceholder from 'react-placeholder';

// import 'react-placeholder/lib/reactPlaceholder.css';


/*
export default function asyncComponent(importComponent) {
  class AsyncFunc extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Comp: null,
      };
    }
    componentDidMount() {
      importComponent()
        .then(Comp => this.setState({
          Comp,
        }))
        .catch(err => this.setState({
          error: err,
        }));
    }
    render() {
      if (this.state.error) {
        return (<h2> Loading error
          <button onClick={e => this.componentDidMount()}> Try again </button>
                </h2>);
      }
      const Comp = this.state.Comp;
      return Comp ?
        <Comp {...this.props} /> :
        <div> <Loading /> loading... </div>;
    }
  }
  return AsyncFunc;
}
*/


export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }
    componentWillMount() {
      Nprogress.start();
    }

    async componentDidMount() {
      this.mounted = true;
      const { default: Component } = await importComponent();
      Nprogress.done();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />,
        });
      }
    }
    componentWillUnmount() {
      this.mounted = false;
    }
    render() {
      const Component = this.state.component || <div />;
      return (
        <div>
          { Component }
        </div>
      );
    }
  }
  return AsyncFunc;

  // https://medium.com/front-end-hacking/lazy-loading-with-react-and-webpack-2-8e9e586cf442
}

/*
<ReactPlaceholder type="text" rows={7} ready={Component !== null}>
  {Component}
</ReactPlaceholder>
*/

