import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import classNames from 'classnames';

import createBrowserHistory from 'history/createBrowserHistory';

import { hot } from 'react-hot-loader';

// Routing
import Routes from 'Core/Routes';

// Material UI

// CSS
import componentStyles from './styles.css';
import componentStylesEntireSite from 'Components/_CommonStyles/commonStyles.css';

// Components
import AuthRoute from './authRoute';
import AdminRoute from './adminRoute';

// <Route path="/home(/:pathParam1)(/:pathParam2)" component={HomePage} />

// Helpers

// Actions

const browserHistory = createBrowserHistory();

class Root extends Component {

  render() {


    return (
      <Provider store={this.props.store}>
        <div className={classNames({ pageWrapper: true })}>
          <Router history={browserHistory}>
            <Switch>

              {Routes.map((singleRoute) => {
                const {
                path, exact, permission, ...otherProps
                } = singleRoute;

                if (permission.length === 0) {
                  return (
                    <Route
                      exact={exact !== false}
                      key={singleRoute.path}
                      path={`/${singleRoute.path}`}
                      {...otherProps}

                    />
                  );
                } else if (permission.includes('admin')) {
                  return (
                    <AdminRoute
                      exact={exact !== false}
                      key={singleRoute.path}
                      path={`/${singleRoute.path}`}
                      {...otherProps}
                    />
                  );
                }
                  return (
                    <AuthRoute
                      exact={exact !== false}
                      key={singleRoute.path}
                      path={`/${singleRoute.path}`}
                      {...otherProps}
                    />
                  );
              })}


            </Switch>
          </Router>
        </div>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

// export default Root;
export default hot(module)(Root);

/* ASYNC EXAMPLE
getAnime = async (query, variables) => {
  try {
    const response = await axios.post('https://graphql.anilist.co', {
      query,
      variables
    });

    // Log the response so we can look at it in the console
    console.log(response.data)

    // Set the data to the state
    this.setState(() => ({
      isLoaded: true,
      items: response.data.data.Page.media
    }));

  } catch (error) {
    // If there's an error, set the error to the state
    this.setState(() => ({ error }))
  }
}
*/

/*
EXAMPLE AUTH MODULE - should do this via component...
export default (
  <Route path="/" component={App}>
    <IndexRoute component={HomePage} />
    <Route path="/login" component={LogInPage} />
    <Route path="/cats" component={CatsPage}
      onEnter={requireAuth}>
      <Route path="/cats/new" component={NewCatPage} />
      <Route path="/cats/:id" component={CatPage} />
    </Route>
    <Route path="/about" component={AboutPage} />
  </Route>
);

function requireAuth(nextState, replace) {
  if (!sessionStorage.jwt) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}


/*
              <Route exact path="/landing" component={LandingPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/home" component={HomePage} />

              <AuthRoute exact path="/my-account/subscriptions" component={MyAccountSubscriptionsPage} />
              <AuthRoute exact path="/my-account/details" component={MyAccountDetailsPage} />
              <AuthRoute exact path="/my-account" component={MyAccountPage} />


              <Route exact path="/api/users/get" component={TestApi} />

              <AdminRoute exact path="/admin" component={AdminPage} />
              <Route exact path="/" component={HomePage} />
              */
