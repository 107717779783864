import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Redirect } from 'react-router-dom';

class AuthRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    console.log(`Auth Check: ${this.props.isAuthenticated}`);

    if (this.props.isAuthenticated) {
      return <Route {...this.props} />;
    }
    return <Redirect to="/login" />;
  }
}
const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AuthRoute);
