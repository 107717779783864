/** *** SIDEBAR ***** */
const initialState = {
  sidebarOpen: false,
};

const SideBarReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_SIDEBAR_OPEN_STATE':
    {
      const clone = Object.assign({}, state);
      clone.sidebarOpen = action.sidebarOpen;
      return clone;
    }

    default:
      return state;
  }
};

export default SideBarReducer;
