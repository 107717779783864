/** *** USER SEARCH ***** */
const initialState = {
  overlayOnScreen: '',
  overlayOnScreenData: '',

  overlayOnScreenResponsive: '',
  overlayOnScreenResponsiveData: '',
};

const CommonActionsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'TOGGLE_OVERLAY_ON_SCREEN':
    {
      const clone = Object.assign({}, state);
      if (action.request === 'open') {
        clone.overlayOnScreen = action.id;
        clone.overlayOnScreenData = action.data;
      }
      if (action.request === 'close') {
        clone.overlayOnScreen = '';
        clone.overlayOnScreenData = '';
      }
      return clone;
    }

    case 'TOGGLE_OVERLAY_ON_SCREEN_RESPONSIVE':
    {
      const clone = Object.assign({}, state);
      if (action.request === 'open') {
        clone.overlayOnScreenResponsive = action.id;
        clone.overlayOnScreenResponsiveData = action.data;
      }
      if (action.request === 'close') {
        clone.overlayOnScreenResponsive = '';
        clone.overlayOnScreenResponsiveData = '';
      }
      return clone;
    }

    default:
      return state;
  }
};

export default CommonActionsReducer;
