import React, { Component } from 'react';
import asyncComponent from "Helpers/AsyncFunc";
const Routes = [
  {
    path: "",
    navPosition: 94,
    component: asyncComponent(() => import("Containers/PublicHomePage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Home',
    navTitleExpanded: 'Home',
    contentTitle: 'Home',
    

    metaTitle: 'The Home Page',
    metaDesc: 'home page metaDesc',
    headerTitlenav: 'Home Nav',
    headerTitlebar: 'Home',

//linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "home",
    navPosition: 5,
    component: asyncComponent(() => import("Containers/PublicHomePage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-home',
    navTitleCollapsed: 'Home',
    navTitleExpanded: 'Home',
    contentTitle: 'Home',


    metaTitle: 'The Home Page',
    metaDesc: 'home page metaDesc',
    headerTitlenav: 'Home Nav',
    headerTitlebar: 'Home',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],


    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "login",
    navPosition: 99,
    component: asyncComponent(() => import("Containers/PublicUserLoginPage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Login',
    navTitleExpanded: 'Login',
    contentTitle: 'Login',

    //META tags
    metaTitle: 'Login',
    metaDesc: 'Login',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Login',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Login',
    ogType: '',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/login',
        title: 'Login',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },

  {
    path: "sign-up",
    navPosition: 98,
    component: asyncComponent(() => import("Containers/PublicUserSignUpPage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Sign Up',
    navTitleExpanded: 'Sign Up',
    contentTitle: 'Sign Up',

    //META tags
    metaTitle: 'Sign Up',
    metaDesc: 'Sign Up',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Sign Up',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Sign Up',
    ogType: '',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/sign-up',
        title: 'Sign Up',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "logout",
    navPosition: 97,
    component: asyncComponent(() => import("Containers/PublicUserLogoutPage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Logout',
    navTitleExpanded: 'Logout',
    contentTitle: 'Logout',

    //META tags
    metaTitle: 'Logout',
    metaDesc: 'Logout',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Logout',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Logout',
    ogType: '',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/login',
        title: 'Login',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "delete",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/PublicUserDeletePage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Logout',
    navTitleExpanded: 'Logout',
    contentTitle: 'Logout',

    //META tags
    metaTitle: 'Logout',
    metaDesc: 'Logout',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Logout',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Logout',
    ogType: '',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/login',
        title: 'Login',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "terms-and-conditions",
    navPosition: 20,
    component: asyncComponent(() => import("Containers/PublicTermsAndConditionsPage")),
    permission: [],
    navVisible: true,
    displayicon: 'fas fa-home',
    navTitleCollapsed: 'T&Cs',
    navTitleExpanded: 'Terms & Conditions',
    contentTitle: 'Terms & Conditions',


    metaTitle: 'Terms & Conditions',
    metaDesc: 'Terms & Conditions',
    headerTitlenav: 'Terms & Conditions',
    headerTitlebar: 'Terms & Conditions',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],


    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
      {
        url: '/terms-and-conditions',
        title: 'Terms & Conditions',
      },
    ],
  },
 
  
  
  

  {
    path: "account/dashboard",
    navPosition: 1,
    component: asyncComponent(() => import("Containers/AccountUserDashboardPage")),
    permission: ["auth"],
    navVisible: true,
    displayicon: 'fas fa-lock',
    navTitleCollapsed: 'Dashboard',
    navTitleExpanded: 'Account Dashboard',
    contentTitle: 'Account Dashboard',

    //META tags
    metaTitle: 'Account | Dashboard',
    metaDesc: 'Account Dashboard',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Account | Dashboard',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Account Dashboard',
    ogType: 'article',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Account',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  
  {
    path: "account/settings",
    navPosition: 3,
    component: asyncComponent(() => import("Containers/AccountUserSettingsProfilePage")),
    permission: ["auth"],
    navVisible: true,
    displayicon: 'fas fa-lock',
    navTitleCollapsed: 'Settings',
    navTitleExpanded: 'Settings',
    contentTitle: 'Settings',

    //META tags
    metaTitle: 'Account | Settings',
    metaDesc: 'Settings',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Account | Settings',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Account Settings',
    ogType: 'article',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],   //Not added by design

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Account',
      },
      {
        url: '/account/settings',
        title: 'Settings',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "account/settings/profile",
    navPosition: 6,
    component: asyncComponent(() => import("Containers/AccountUserSettingsProfilePage")),
    permission: ["auth"],
    navVisible: false,
    displayicon: 'fas fa-lock',
    navTitleCollapsed: 'SettiProfilengs',
    navTitleExpanded: 'Profile',
    contentTitle: 'Profile',

    //META tags
    metaTitle: 'Account | Profile',
    metaDesc: 'Profile',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Account | Profile',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Account Profile',
    ogType: 'article',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['accountusersettings','adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Account',
      },
      {
        url: '/account/settings/profile',
        title: 'Profile',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  






  {
    path: "landing",
    navPosition: 8,
    component: asyncComponent(() => import("Containers/PublicLandingPage")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Landing',
    navTitleExpanded: 'Landing',
    contentTitle: 'Landing',

    //META tags
    metaTitle: 'The Landing Page',
    metaDesc: 'landing page metaDesc',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'SmartHome Landing Page',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Description for SmartHome.Cloud Landing Page',
    ogType: 'article',
    ogImage: 'http://localhost/test.png',
    ogURL: 'http://smarthome.cloud/landing',


    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
      {
        url: '/home',
        title: 'Go Home',
      },
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  
  
  {
    path: "cart",
    navPosition: 30,
    component: asyncComponent(() => import("Containers/ShoppingCartPage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-shopping-cart',
    navTitleCollapsed: 'Cart',
    navTitleExpanded: 'Cart',
    contentTitle: 'Cart',

    //META tags
    metaTitle: 'Cart',
    metaDesc: 'Cart',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: '',
    ogSitename: 'SmartHome.cloud',
    ogDesc: '',
    ogType: '',
    ogImage: '',
    ogURL: '',


    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
      {
        url: '/cart',
        title: 'Cart',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "checkout",
    navPosition: 32,
    component: asyncComponent(() => import("Containers/ShoppingCheckoutPage")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-credit-card',
    navTitleCollapsed: 'Checkout',
    navTitleExpanded: 'Checkout',
    contentTitle: 'Checkout',

    //META tags
    metaTitle: 'Checkout',
    metaDesc: 'Checkout',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Checkout',
    ogSitename: 'SmartHome.cloud',
    ogDesc: 'Checkout',
    ogType: '',
    ogImage: '',
    ogURL: '',


    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
      {
        url: '/cart',
        title: 'Cart',
      },
      {
        url: '/checkout',
        title: 'Checkout',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },





  
  


  
  
]
export default Routes;
