/** *** AUTH ***** */
/* USAGE
isAuthenticated = basic check to determine if the user is unknown or authed
isAdmin = whether the user is an Admin (supporting other admin levels?)
  > would allow the user to see the links for the admin section (when the page loads,
    auth route validation to protect the view/data)

> Below service would allow the ability to change views/links/notices etc on the front end
serviceBasic: true/false  - Basic service account (subscription)
servicePower: true/false  - Power service account (sub)
servicePro: true/false    - *
serviceAgency: true/false - *

*/




const initialState = {
  apiAccessToken: '',
  apiRefreshtoken: '',
  authToken: '',
  isAuthenticated: false,
  isAdmin: false,
  userAuthObj: '',
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_AUTH':
    {
      const clone = Object.assign({}, state);
      console.log('user auth');
      clone.apiAccessToken = action.data.accessToken;
      clone.apiRefreshtoken = action.data.refreshToken;

      clone.authToken = action.data.accessToken;
      clone.isAuthenticated = true;
      return clone;
    }

    case 'REMOVE_USER_AUTH':
    {
      console.log('removing user auth');
      return initialState;
    }





    case 'SET_USER_AUTH_OBJ':
    {
      const clone = Object.assign({}, state);
      clone.userAuthObj = action.user;
      return clone;
    }


    case 'SET_USER_LOGOUT':
    {
      const clone = Object.assign({}, state);
      clone.isAuthenticated = false;
      return clone;
    }


    case 'SET_LOGGEDIN_USER':
    {
      const clone = Object.assign({}, state);
      clone.user = action.user;
      clone.loggedInEvent = true;
      clone.isAuthenticated = true;
      return clone;
    }

    case 'SET_LOGGEDOUT_USER':
    {
      const clone = Object.assign({}, state);
      clone.user = '';
      clone.isAuthenticated = false;
      return clone;
    }

    case 'SET_AUTHENTICATED':
    {
      const clone = Object.assign({}, state);
      clone.isAuthenticated = action.loginValue;
      return clone;
    }


    default:
      return state;
  }
};

export default AuthReducer;
