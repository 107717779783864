import { combineReducers } from 'redux';

import UI from './UI';
import Auth from './Auth';
import User from './User';

import Sidebar from './Sidebar';
import CommonActions from './CommonActions';


const myStuff = combineReducers({
  UI,
  Auth,
  User,
  Sidebar,
  CommonActions,
});

export default myStuff;
