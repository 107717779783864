import React from 'react';
import { render } from 'react-dom';
import store from 'Store/ConfigureStore';
import Root from 'Containers/Root';
import { saveState } from './store/localStorage';
// import Basic from 'Containers/Basic';


if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}


store.subscribe(() => {
  saveState(store.getState());
});


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}



render(
  <Root store={store} />,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}

// https://reacttraining.com/react-router/web/guides/code-splitting

/*
//https://firebase.google.com/docs/firestore/quickstart?authuser=0

const admin = require('firebase-admin');
const functions = require('firebase-functions');

admin.initializeApp(functions.config().firebase);

const db = admin.firestore();

db.collection('usertest').get()
  .then((snapshot) => {
    snapshot.forEach((doc) => {
      console.log(doc.id, '=>', doc.data());
    });
  })
  .catch((err) => {
    console.log('Error getting documents', err);
  });
*/
