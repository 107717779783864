import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import reducer from 'Reducers';
import { loadState } from 'Store/localStorage';

const persistedState = loadState();

export const history = createHistory();

const reactRouterMiddleware = routerMiddleware(history);
const middlewares = [
  promise(),
  thunk,
  logger,
  reactRouterMiddleware,
];

export default createStore(reducer, persistedState, applyMiddleware(...middlewares));
